<template>
  <b-container class="import-wizard-step04 import-wizard-step">
    <b-row no-gutters>
      <b-col cols="12" lg="4" class="sidebar-text detail-text text-left pt-3 pb-1 px-2 pt-md-0 pb-md-2 px-md-2 py-lg-5 px-lg-5">
        <h2 class="text-dark">{{ $t(this.translationPath + 'title') }} </h2>
        <i18n :path="translationPath + 'intro_p1'" tag="p"></i18n>
        <i18n :path="translationPath + 'intro_p2'" tag="p">
          <b-link slot="asset_link" to="/reports/other/account-plan-v2/assets/auto-import">{{ $t('common.here') }}</b-link>
          <b-link slot="liability_link" to="/reports/other/account-plan-v2/liabilities/auto-import">{{ $t('common.here') }}</b-link>
        </i18n>
      </b-col>
      <b-col cols="12" lg="8" class="accounts_wrapper py-4 pl-2 pr-3 pr-md-0">
        <template v-if="!isTakingTransactions">
          <b-tabs card class="transparent-tabs">
            <b-tab :title="$t(translationPath + 'tab_title_import')">
              <p>{{ $t(translationPath + 'import_date_description') }}</p>

              <b-form-group
                label-for="start-date"
                label-class="align-self-center pr-md-0 pr-lg-4 text-black"
                label-cols-lg="3"
                label-cols-xl="3"
                label-align-lg="right"
                required
                class="pt-md-4"
                :state="$v.startDate.$dirty ? !$v.startDate.$error : null"
                :invalid-feedback="invalidStartDateFeedback"
              >
                <template slot="label">{{ $t(translationPath + 'start_date_label') }}</template>
                <datepicker
                  id="start-date"
                  name="start-date"
                  v-model="startDate"
                  input-class="form-control input-date"
                  :typeable="true"
                  :required="true"
                  :format="'yyyy-MM-dd'"
                  :placeholder="'YYYY-MM-DD'"
                  :language="selectedLang"
                  :monday-first="startWeekByMonday"
                  @input="$v.dates.$touch()"
                ></datepicker>
              </b-form-group>

              <b-form-group
                label-for="end-date"
                label-class="align-self-center pr-md-0 pr-lg-4 text-black"
                label-cols-lg="3"
                label-cols-xl="3"
                label-align-lg="right"
                required
                class="pt-md-4"
                :state="$v.endDate.$dirty ? !$v.endDate.$error : null"
                :invalid-feedback="invalidEndDateFeedback"
              >
                <template slot="label">{{ $t(translationPath + 'end_date_label') }}</template>
                <datepicker
                  id="end-date"
                  name="end-date"
                  v-model="endDate"
                  input-class="form-control input-date"
                  :typeable="true"
                  :required="true"
                  :format="'yyyy-MM-dd'"
                  :placeholder="'YYYY-MM-DD'"
                  :invalid-feedback="invalidEndDateFeedback"
                  :language="selectedLang"
                  :monday-first="startWeekByMonday"
                  @input="$v.dates.$touch()"
                ></datepicker>
              </b-form-group>

              <p>{{ $t(translationPath + 'import_date_description2') }}</p>
            </b-tab>
            <b-tab v-if="latestImports.length" class="mt-3" :title="$t(translationPath + 'tab_title_imports')">
              <import-tink2-wizard-step-04-latest-imports
                :account="offsetAccount"
                :imports="latestImports"
                :is-loaded="isLatestImportsLoaded"
              ></import-tink2-wizard-step-04-latest-imports>
            </b-tab>
            <b-tab class="mt-3" :title="$t(translationPath + 'tab_title_transactions')">
              <import-tink2-wizard-step-04-latest-transactions
                :account="offsetAccount"
              ></import-tink2-wizard-step-04-latest-transactions>
            </b-tab>
          </b-tabs>
        </template>
        <template v-else>
          <b-col>
            <loader :loading-text="$t('transactions.import.import_tink.step4.loading_transactions')" />
          </b-col>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import Datepicker from 'vuejs-datepicker'
import { en, da, sv } from 'vuejs-datepicker/dist/locale'
import Loader from '@/components/common/Loader'
import ImportTink2WizardStep04LatestImports from './ImportTinkWizardStep04LatestImports'
import ImportTink2WizardStep04LatestTransactions from './ImportTinkWizardStep04LatestTransactions'

const isEndDateGreaterThanStart = getter => function () {
  return this.startDate < this.endDate
}
const startDateNotFuture = getter => function () {
  return this.toTzString(this.startDate) <= this.toTzString(new Date())
}
const endDateNotAfterRefreshDate = getter => function () {
  if (!this.connectionRefreshedAt) {
    return true
  }
  return this.toTzString(this.endDate) <= this.connectionRefreshedAt
}
const endDateNotFuture = getter => function () {
  return this.toTzString(this.endDate) <= this.toTzString(new Date())
}

export default {
  name: 'TinkImportWizardStep04',
  components: { Datepicker, Loader, ImportTink2WizardStep04LatestImports, ImportTink2WizardStep04LatestTransactions },
  props: {
    offsetAccount: {
      type: Object
    },
    tinkProvider: {
      type: Object
    },
    tinkAccount: {
      type: String,
      default: ''
    },
    connection: {
      type: Object,
      default () { return {} }
    },
    lastTransactionDates: {
      type: Object,
      default () { return {} }
    },
    connectionRefreshedAt: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      translationPath: 'transactions.import.import_tink.step4.',
      startDate: null,
      endDate: null,
      startWeekByMonday: Object.prototype.hasOwnProperty.call(process.env, 'VUE_APP_DATEPICKER_START_DAY_BY_MONDAY') && process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY ? process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY === 'true' : false,
      datepickerLang: {
        en: en,
        sv: sv,
        da: da
      },
      isTakingTransactions: false,
      latestImports: [],
      isLatestImportsLoaded: false
    }
  },
  validations: {
    startDate: { required, validDateNotFuture: startDateNotFuture() },
    endDate: {
      required,
      endDateGreaterThanStart: isEndDateGreaterThanStart(),
      validDateNotFuture: endDateNotFuture(),
      endDateNotAfterRefreshDate: endDateNotAfterRefreshDate()
    },
    dates: ['startDate', 'endDate']
  },
  computed: {
    isValid () {
      const today = this.toTzString(new Date())
      return this.startDate && this.endDate && this.startDate < this.endDate && this.toTzString(this.startDate) <= today && this.toTzString(this.endDate) <= today
    },
    invalidStartDateFeedback () {
      if (this.$v.startDate.required === false) return this.$t(this.translationPath + 'errors.start_date_required')
      if (this.$v.startDate.validDateNotFuture === false) return this.$t('common.future_dates_not_allowed')
      return ''
    },
    invalidEndDateFeedback () {
      if (this.$v.endDate.required === false) return this.$t(this.translationPath + 'errors.end_date_required')
      if (this.$v.endDate.validDateNotFuture === false) return this.$t('common.future_dates_not_allowed')
      if (this.$v.endDate.endDateNotAfterRefreshDate === false) return this.$t(this.translationPath + 'errors.end_date_after_refresh_date', { latest_update_date: this.connectionRefreshedAt.substr(0, 10) })
      if (this.$v.endDate.endDateGreaterThanStart === false) return this.$t(this.translationPath + 'errors.end_date_should_be_greater')
      return ''
    },
    selectedLang () {
      return this.datepickerLang[this.$i18n.locale]
    },
    offsetAccountTitle () {
      if (this.offsetAccount) {
        return this.offsetAccount.title
      } else {
        return ''
      }
    }
  },
  methods: {
    emit () {
      this.$emit('validate-success', this.isValid)
      if (this.isValid) {
        this.$emit(
          'step-valid',
          {
            startDate: this.toTzString(this.startDate),
            endDate: this.toTzString(this.endDate)
          }
        )
      }
    },
    toTzString (oDate) { // Avoid timezone offset pitfall when use toISOString() on date object.
      const y = oDate.getFullYear().toString()
      let m = (oDate.getMonth() + 1).toString()
      let d = oDate.getDate().toString()
      if (m < 10) {
        m = '0' + m
      }
      if (d < 10) {
        d = '0' + d
      }
      return y + '-' + m + '-' + d
    },
    async validate () {
      if (!this.isValid) {
        return false
      }

      this.isTakingTransactions = true
      this.$bvToast.toast(this.$t(this.translationPath + 'trying_to_parse_description'), {
        title: this.$t(this.translationPath + 'trying_to_parse_title'),
        variant: 'info',
        solid: true
      })

      const status = await axios.get(`${process.env.VUE_APP_ROOT_API}/tink/search/${this.connection.id}?start_date=${this.toTzString(this.startDate)}&end_date=${this.toTzString(this.endDate)}&tink_account_id=${this.tinkAccount}&offset_account_id=${this.offsetAccount.id}`)
        .then(response => {
          const transactions = response.data.data.transactions

          transactions.map(transaction => {
            transaction.percentage = transaction.suggested_account ? transaction.suggested_account.default_active_percentage : 0
            transaction.suggestion_account_id = transaction.suggested_account && Object.prototype.hasOwnProperty.call(transaction.suggested_account, 'id') && transaction.suggested_account.id != null && transaction.suggested_account.id >= 0 ? transaction.suggested_account.id : -1

            // Since API sends only transfer as type, we need to correct it for frontend to select correct type
            if (transaction.type === 'transfer') {
              transaction.type = transaction.is_identified_as_transfer_type
            }
          })

          // eslint-disable-next-line camelcase
          const first_transaction_date = transactions.length > 0 ? transactions[0].date : null
          // eslint-disable-next-line camelcase
          const last_transaction_date = first_transaction_date && transactions.length > 1 ? transactions[transactions.length - 1].date : null
          // transactions parser
          const transactionParser = {
            transactions: transactions,
            parse_result: {
              raw_data_string: response.data.data.raw_transactions_string,
              parser_class_name: this.tinkProvider.provider_name,
              parser_nice_name: this.tinkProvider.translation_string_id.replace('common.', ''),
              success_rate: 100,
              first_transaction_date,
              last_transaction_date
            }
          }
          this.$emit('transactions-retrieved', transactionParser)

          axios.post(`${process.env.VUE_APP_ROOT_API}/bankstatements/import/create`, {
            raw_account_statement: response.data.data.raw_transactions_string,
            title: 'Tink import created: ' + new Date().toISOString().slice(0, 19).replace('T', ' '),
            account_id: this.offsetAccount.id,
            used_parser: '-',
            success_rate: 0
          })
            .then(response => {
              this.$emit('import-created', { import_id: response.data.data.id })
            })
            .catch(err => {
              console.error(err)
            })
          this.isTakingTransactions = false
          return true
        })
        .catch(err => {
          console.error(err)
          this.isTakingTransactions = false
          return false
        })

      return status
    },
    loadImports () {
      if (!this.offsetAccount) {
        return false
      }

      this.isLatestImportsLoaded = false
      this.latestImports = []
      axios.get(`${process.env.VUE_APP_ROOT_API}/bankstatements/import/by-account-id/${this.offsetAccount.id}`)
        .then(response => {
          this.latestImports = response.data.data.filter(item => item.is_completed).slice(0, 10)
        })
        .catch(err => {
          console.error(err)
        })
        .then(() => {
          this.isLatestImportsLoaded = true
        })
    }
  },
  created () {
    this.endDate = new Date()
    this.startDate = new Date()
    this.startDate.setMonth(this.startDate.getMonth() - 1)
  },
  watch: {
    'offsetAccount.id' () {
      this.loadImports()
    },
    startDate () {
      this.emit()
    },
    endDate () {
      this.emit()
    },
    lastTransactionDates (dates) {
      if (!this.offsetAccount) {
        return false
      }

      if (!Object.prototype.hasOwnProperty.call(dates, this.offsetAccount.id)) {
        return false
      }

      if (dates[this.offsetAccount.id] == null) {
        this.endDate = new Date()
        this.startDate = new Date()
        this.startDate.setMonth(this.startDate.getMonth() - 1)
      } else {
        this.startDate = new Date(dates[this.offsetAccount.id])
        this.endDate = new Date()
      }
    },
    connectionRefreshedAt (newVal) {
      if (newVal) {
        const endDate = new Date(newVal)
        this.endDate = endDate.toString() !== 'Invalid Date' ? endDate : new Date()
      }
      if (newVal && this.toTzString(this.endDate) > newVal) {
        this.$v.dates.$touch()
      }
    }
  }
}
</script>
