<template>
  <b-container class="import-wizard-step01 import-wizard-step">
    <b-row no-gutters>
      <b-col cols="12" lg="4" class="sidebar-text detail-text text-left pt-3 pb-1 px-2 pt-md-0 pb-md-2 px-md-2 py-lg-5 px-lg-5">
        <h2 class="text-dark">{{ $t(this.translationPath + 'title') }} </h2>
        <div v-html="$t(translationPath + 'description')"></div>
      </b-col>
      <b-col cols="12" lg="8" class="accounts_wrapper py-3 pl-2 pr-3 pr-md-0 py-lg-5 pl-lg-5 pr-lg-0">
        <b-table ref="importTable" class="spirecta-simple-table accounts-table spirecta-table-where-row-is-selected" show-empty hover responsive striped
                 :items="accounts"
                 :fields="fields"
                 :busy="busyState"
                 selectable
                 select-mode="single"
                 :filter="filter"
                 :filter-included-fields="['title','title_with_code']"
                 @row-selected="rowSelected"
                 stacked="md"
                 :emptyText="$t(translationPath + 'no_data')"
          >
          <template v-slot:table-busy>
            <loader/>
          </template>

          <!-- FILTER -->
          <template slot="top-row">
            <td colspan="3">
              <div class="d-flex align-items-center">
                <i class="fa fa-search text-secondary"></i>
                <b-form-input v-model="filter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
              </div>
            </td>
          </template>

          <template v-slot:head(title)>
            {{ $t(translationPath + 'table_head') }}
            <br />
            <i18n :path="translationPath + 'table_heading_description_missing_account'" tag="small" class="text-gray">
              <b-link slot="importable_accounts_read_more" v-b-toggle.account_missing>{{ $t(translationPath + 'importable_accounts_read_more')}}</b-link>
            </i18n>
          </template>

          <template v-slot:cell(title)="data">
            {{ currentCOA.prefix_account_title_with_code ? data.item.title_with_code : data.item.title }}
          </template>
          <template v-slot:cell(importable_type)="data">
            <b-link class="text-gray"> {{data.value}} </b-link>
          </template>
          <template v-slot:cell(tink_input_provider)="row">
            {{ row.item.tink_provider ? $t(row.item.tink_provider.translation_string_id) : '' }}
          </template>
          <template v-slot:cell(tink_id)="row">
            {{ row.item.tink_name }}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import Loader from '@/components/common/Loader'

export default {
  name: 'ImportTinkWizardStep01',
  components: { Loader },
  data () {
    return {
      translationPath: 'transactions.import.import_tink.step1.',
      accounts: [],
      busyState: true,
      selected: [],
      filter: '',
      lastTransactionDates: {}
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    fields () {
      return [
        { key: 'title', label: this.$t('common.source'), sortable: false, class: 'td-title' },
        { key: 'tink_input_provider', label: this.$t('common.bank'), sortable: false },
        { key: 'tink_id', label: this.$t(this.translationPath + 'account_no'), sortable: false }
      ]
    }
  },
  methods: {
    async loadAccounts () {
      const sSortTitle = this.currentCOA.prefix_account_title_with_code === 1 ? 'code' : 'title'
      await axios.get(`${process.env.VUE_APP_ROOT_API}/accounts?account_type=asset,liability&only_importable=1&hide_inactive=1&sort[0][key]=${sSortTitle}&sort[0][direction]=asc`)
        .then(response => {
          this.accounts = response.data.data
          this.accounts.map(account => {
            this.lastTransactionDates[account.id] = null
          })
          this.busyState = false
        })
        .catch(error => {
          console.error(error)
          this.busyState = false
        })
    },
    loadLastTransactionDate (account) {
      if (this.lastTransactionDates[account.id]) {
        return false
      }

      axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/firstAndLastTransaction/${account.id}`)
        .then(response => {
          this.lastTransactionDates[account.id] = response.data.data[0].last_transaction_date
          this.$emit('last-transaction-date-received', { lastTransactionDates: this.lastTransactionDates })
        })
        .catch(err => {
          console.error(err)
        })
    },
    rowSelected (items) {
      this.selected = items
      this.emit()

      if (items[0]) {
        this.loadLastTransactionDate(items[0])
      }
    },
    emit () {
      this.$emit('import-selected', { offset_account: this.selected.length ? this.selected[0] : null })
      this.$emit('validate-success', this.selected.length !== 0)
    },
    async validate () {
      return this.selected.length !== 0
    }
  },
  created () {
    this.loadAccounts()
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/spirecta-table-where-row-is-selected.scss';
</style>
