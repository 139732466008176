<template>
  <b-container class="import-wizard-step03 import-wizard-step">
    <b-row no-gutters>
      <b-col cols="12" lg="4" class="sidebar-text detail-text text-left pt-3 pb-1 px-2 pt-md-0 pb-md-2 px-md-2 py-lg-5 px-lg-5">
        <h2 class="text-dark">{{ $t(translationPath + 'title') }} </h2>
        <div v-html="$t(translationPath + 'description')"></div>
      </b-col>

      <b-col cols="12" lg="8" class="accounts_wrapper py-4 pl-2 pr-3 pr-md-0">
        <b-row>
          <b-col class="offset-1 pt-4">
            <i18n :path="translationPath + 'descriptive_heading'" tag="h2">
              <b v-if="tinkProvider" slot="provider">{{ $t(tinkProvider.translation_string_id) }}</b>
            </i18n>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label-class="align-self-center pr-md-0 pr-lg-4 text-black"
              label-for="tink-provider"
              label-cols-lg="3"
              label-cols-xl="3"
              label-align-lg="right"
              required
              class="pt-md-4"
              :description="$t(translationPath + 'tink_provider_description')"
            >

              <template slot="label">{{ $t(translationPath + 'tink_provider_label') }}</template>
              <b-form-input :disabled="true" :value="tinkProvider ? $t(tinkProvider.translation_string_id) : ''"></b-form-input>
            </b-form-group>
            <b-button v-if="isSaveProviderBtnShown" class="btn btn-sm mt-2 float-right" variant="primary" @click="saveDefaultProviderForAccount">{{ $t(translationPath + 'save_as_default_input_provider', {account_name: offsetAccountTitle}) }}</b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="">
            <b-form-group
              label-class="align-self-center pr-md-0 pr-lg-4 text-black"
              label-cols-lg="3"
              label-cols-xl="3"
              label-align-lg="right"
              required
              class="pt-md-4"
              :description-old="$t(translationPath + 'refreshed_at_description') + (isRefreshButtonShown ? ' ' + $t(translationPath + 'refreshed_at_description_30min') : '' )"
              :description="$t(translationPath + 'refreshed_at_description')"
            >
              <template slot="label">{{ $t(translationPath + 'refreshed_at_label') }}</template>
              <b-form-input
                disabled
                v-model="refreshedAt"
              ></b-form-input>
            </b-form-group>
            <b-button v-if="isRefreshButtonShown" class="btn btn-sm mt-2 float-right" variant="primary" @click="onRefreshConnectionClick">{{ $t(translationPath + 'refresh_connection') }}</b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="offset-1 pt-4">
            <i18n :path="translationPath + 'save_account_heading'" tag="h3">
              <b v-if="tinkProvider" slot="provider">{{ $t(tinkProvider.translation_string_id) }}</b>
            </i18n>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="">
            <b-form-group
              label-class="align-self-center pr-md-0 pr-lg-4 text-black"
              label-for="connection-name"
              label-cols-lg="3"
              label-cols-xl="3"
              label-align-lg="right"
              required
              class="pt-md-4"
              :invalid-feedback="invalidConnectionNameFeedback"
              :description="$t(translationPath + 'connection_name_description')"
            >
              <template slot="label">{{ $t(translationPath + 'connection_name_label') }}</template>
              <b-form-input
                v-model="connectionName"
                id="connection-name"
                :state="$v.connectionName.$dirty ? !$v.connectionName.$error : null"
                @input="onConnectionNameInput"
              ></b-form-input>
            </b-form-group>
            <b-button v-if="isSaveConnectionBtnShown" class="btn btn-sm mt-2 mr-2 float-right" variant="primary" @click="saveCurrentConnection">{{ $t(translationPath + 'tink_save_connection_btn') }}</b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="offset-1 pt-4">
            <i18n :path="translationPath + 'match_account_heading'" tag="h3">
              <b v-if="tinkProvider" slot="provider">{{ $t(tinkProvider.translation_string_id) }}</b>
              <b slot="offset_account">{{ offsetAccountTitle }}</b>
            </i18n>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="">
            <b-form-group
              label-class="align-self-center pr-md-0 pr-lg-4 text-black"
              label-for="tink-account"
              label-cols-lg="3"
              label-cols-xl="3"
              label-align-lg="right"
              required
              class="pt-md-4"
              :invalid-feedback="invalidTinkAccountFeedback"
              :description="$t(translationPath + 'account_description', {account_name: offsetAccountTitle})"
            >
              <template slot="label">{{ $t(translationPath + 'tink_account_label') }}</template>
              <loader v-if="!isTinkAccountsLoaded" align="left" :loading-text="$t(translationPath + 'tink_accounts_are_loading')"></loader>
              <b-form-select
                v-if="isTinkAccountsLoaded"
                id="tink-account"
                v-model="tinkAccount"
                :options="tinkAccountOptions"
                :state="$v.tinkAccount.$dirty ? !$v.tinkAccount.$error : null"
                @change="$v.tinkAccount.$touch()"
              ></b-form-select>
            </b-form-group>
            <template v-if="isSaveDefaultAccountBtnShown">
              <b-button class="btn btn-sm mt-2 float-right" variant="primary" @click="saveDefaultTinkAccountForAccount">{{ $t(translationPath + 'save_as_default_tink_account', {account_name: offsetAccountTitle}) }}</b-button>
            </template>
          </b-col>
        </b-row>

      </b-col>
    </b-row>

    <refresh-tink-connection-modal
      ref="RefreshTinkConnectionModal"
      :connection="this.connection"
      @close="onRefreshConnectionHide"
      @hide="onRefreshConnectionHide"
      @refreshed="onConnectionRefreshed"
    />
  </b-container>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import Loader from '@/components/common/Loader'
// import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import RefreshTinkConnectionModal from '@/components/modals/RefreshTinkConnectionModal'

export default {
  name: 'ImportTinkWizardStep03',
  components: { Loader, RefreshTinkConnectionModal },
  props: {
    offsetAccount: {
      type: Object
    },
    tinkProvider: {
      type: Object
    },
    connection: {
      type: Object
    }
  },
  data () {
    return {
      translationPath: 'transactions.import.import_tink.step3.',
      tinkAccounts: [],
      tinkAccount: null,
      connectionName: null,
      connectionId: null,
      description: null,
      isTinkAccountsLoaded: false,
      isSaveConnectionBtnShown: false,
      isSaveProviderBtnShown: false
    }
  },
  validations: {
    tinkAccount: { required },
    connectionName: { required }
  },
  computed: {
    tinkAccountOptions () {
      const opts = []
      this.tinkAccounts.map(tinkAccount => {
        opts.push({
          text: tinkAccount.name + ' - ' + tinkAccount.number,
          value: tinkAccount.tink_account_id,
          meta: { id: tinkAccount.tink_account_id, number: tinkAccount.number }
        })
      })
      return opts
    },
    offsetAccountTitle () {
      if (this.offsetAccount) {
        return this.offsetAccount.title
      } else {
        return ''
      }
    },
    refreshedAt () {
      if (!this.connection) {
        return ''
      }
      if (Object.prototype.hasOwnProperty.call(this.connection, 'credentials_refreshed_at')) {
        return this.connection.credentials_refreshed_at
      }
      return ''
    },
    isRefreshButtonShown () {
      if (!this.connection) {
        return false
      }
      return true

      /* const serverTime = moment(this.connection.server_time)
      const refreshTime = moment(this.connection.credentials_refreshed_at)

      if (serverTime.diff(refreshTime, 'minutes') >= 30) {
        return true
      }
      return false */
    },
    isSaveDefaultAccountBtnShown () {
      if (!this.offsetAccount || !this.isTinkAccountsLoaded || !this.tinkAccount) {
        return false
      }

      let isInList = false
      for (const i in this.tinkAccountOptions) {
        if (this.tinkAccountOptions[i].meta.id === this.tinkAccount) {
          isInList = true
          break
        }
      }

      let tinkAccountNumber = ''
      for (const i in this.tinkAccounts) {
        if (this.tinkAccounts[i].tink_account_id === this.tinkAccount) {
          tinkAccountNumber = this.tinkAccounts[i].number
          break
        }
      }
      if (isInList && tinkAccountNumber !== this.offsetAccount.tink_name) {
        return true
      }

      return false
    },
    invalidTinkAccountFeedback () {
      if (this.$v.tinkAccount.required === false) { return this.$i18n.t(this.translationPath + 'errors.tink_account_required') }
      return ''
    },
    invalidConnectionNameFeedback () {
      if (this.$v.connectionName.required === false) return this.$t(this.translationPath + 'errors.connection_name_required')
      return ''
    }
  },
  methods: {
    loadTinkAccounts () {
      this.isTinkAccountsLoaded = false
      this.tinkAccounts = []
      axios.get(`${process.env.VUE_APP_ROOT_API}/tink/accounts/${this.connectionId}`)
        .then(response => {
          axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/` + this.offsetAccount.id)
            .then(responseO => {
              const offsetAccount = _.cloneDeep(this.offsetAccount)
              offsetAccount.tink_id = responseO.data.data.tink_id
              if (offsetAccount.tink_input_provider !== this.tinkProvider.provider_name) {
                this.isSaveProviderBtnShown = true
              }
              offsetAccount.tink_input_provider = this.tinkProvider.provider_name
              this.$emit('offset-account-updated', { offset_account: offsetAccount })
            })
            .catch(err => {
              console.error(err)
            })
            .then(() => {
              // If only one account available - preselect it.
              if (!this.tinkAccount && this.tinkAccountOptions.length === 1) {
                this.tinkAccount = this.tinkAccountOptions[0].value
              } else {
                this.tinkAccounts = response.data.data.db_accounts

                // Preselect tink account associated with offset account.
                if (!this.tinkAccount && this.offsetAccount) {
                  for (const i in this.tinkAccounts) {
                    if (this.tinkAccounts[i].number === this.offsetAccount.tink_name) {
                      this.tinkAccount = this.tinkAccounts[i].tink_account_id
                      break
                    }
                  }
                }
              }
            })
        })
        .catch(err => {
          console.error(err)
        })
        .then(() => {
          this.isTinkAccountsLoaded = true
        })
    },
    saveDefaultProviderForAccount () {
      if (!this.offsetAccount) {
        return false
      }

      const putData = { tink_input_provider: this.tinkProvider.provider_name }
      axios.put(`${process.env.VUE_APP_ROOT_API}/accounts/${this.offsetAccount.id}`, putData)
        .then(response => {
          this.isSaveProviderBtnShown = false
          this.emitOffsetAccountUpdated()
          this.$bvToast.toast(this.$t(this.translationPath + 'default_provider_update_success', { provider: this.tinkProvider.display_name, account: this.offsetAccountTitle }), {
            title: this.$t(this.translationPath + 'default_provider_update_title'),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'default_provider_update_fail', { message: this.$t(this.translationPath + 'default_provider_update_fail') }), {
            title: this.$t(this.translationPath + 'default_provider_update_title'),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
    },
    saveCurrentConnection () {
      if (!this.checkIsConnectionCanBeSaved()) {
        return false
      }

      const putData = { name: this.connectionName }
      axios.put(`${process.env.VUE_APP_ROOT_API}/tink/save-connection/${this.connectionId}`, putData)
        .then(response => {
          this.isSaveConnectionBtnShown = false
          this.$emit('connection-modified', { id: this.connectionId })
          this.$bvToast.toast(this.$t(this.translationPath + 'save_connection_success'), {
            title: this.$t(this.translationPath + 'save_connection_title'),
            variant: 'success',
            solid: true
          })
        })
        .catch(err => {
          console.error(err)
        })
    },
    saveDefaultTinkAccountForAccount () {
      if (!this.offsetAccount) {
        return false
      }

      let tinkAccountNumber = ''
      let tinkAccountName = ''
      for (const i in this.tinkAccounts) {
        if (this.tinkAccounts[i].tink_account_id === this.tinkAccount) {
          tinkAccountNumber = this.tinkAccounts[i].number
          tinkAccountName = this.tinkAccounts[i].name
        }
      }

      const putData = { tink_id: this.tinkAccount, tink_name: tinkAccountNumber }
      axios.put(`${process.env.VUE_APP_ROOT_API}/accounts/${this.offsetAccount.id}`, putData)
        .then(response => {
          this.emitOffsetAccountUpdated()
          this.$bvToast.toast(this.$t(this.translationPath + 'tink_account_update_success', { tinkAccount: tinkAccountName, account: this.offsetAccountTitle }), {
            title: this.$t(this.translationPath + 'tink_account_update_title'),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'tink_account_update_fail', { message: this.$t(this.translationPath + 'default_provider_update_fail') }), {
            title: this.$t(this.translationPath + 'tink_account_update_title'),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
    },
    checkIsConnectionCanBeSaved () {
      this.$v.connectionName.$touch()

      if (this.$v.connectionName.$anyError) {
        return false
      }

      if (!this.connectionId) {
        this.$bvToast.toast(this.$t(this.translationPath + 'save_connection_fail'), {
          title: this.$t(this.translationPath + 'save_connection_title'),
          variant: 'danger',
          solid: true
        })
        return false
      }

      return true
    },
    onConnectionNameInput () {
      this.$v.connectionName.$touch()
      this.isSaveConnectionBtnShown = true
    },
    onRefreshConnectionClick () {
      this.$refs.RefreshTinkConnectionModal.$listenEvents()
      this.$refs.RefreshTinkConnectionModal.show()
    },
    onRefreshConnectionHide () {
      this.$refs.RefreshTinkConnectionModal.$removeEvents()
    },
    onConnectionRefreshed (data) {
      this.$refs.RefreshTinkConnectionModal.hide()
      this.$emit('connection-received', { connection: data.connection })
    },
    emitOffsetAccountUpdated () {
      const offsetAccount = _.cloneDeep(this.offsetAccount)
      offsetAccount.tink_input_provider = this.tinkProvider.provider_name
      if (this.tinkAccount) {
        offsetAccount.tink_id = this.tinkAccount
        let tinkAccountNumber = ''
        for (const i in this.tinkAccounts) {
          if (this.tinkAccounts[i].tink_account_id === this.tinkAccount) {
            tinkAccountNumber = this.tinkAccounts[i].number
            break
          }
        }
        offsetAccount.tink_name = tinkAccountNumber
      }
      this.$emit('offset-account-updated', { offset_account: offsetAccount })
    },
    emit () {
      const isValid = Boolean(this.tinkAccount)
      this.$emit('validate-success', isValid)
      if (isValid) {
        this.$emit('step-valid', { tinkAccount: this.tinkAccount })
      }
    },
    validate () {
      return Boolean(this.tinkAccount)
    },
    toastError (msg, title) {
      this.errorMsg = msg
      this.$bvToast.toast(this.$t(this.translationPath + 'connect_to_provider_fail', { message: msg }), {
        title: title,
        variant: 'danger',
        solid: true,
        'auto-hide-delay': 3000
      })
    }
  },
  watch: {
    tinkAccount () {
      this.emit()
    },
    connection: {
      deep: true,
      handler (newItem, oldItem) {
        this.connectionId = newItem.id
        if (!this.connectionName || newItem.id !== oldItem.id || newItem.creadentials_refreshed_at !== oldItem.credentials_refreshed_at) {
          this.connectionName = newItem.name
          const tmp = newItem.name.match(/#.+#/)
          if (tmp !== null) {
            this.connectionName = newItem.name.replace(/#.+#/, this.$t(tmp[0].replaceAll('#', '')))
          }
        }
      }
    }
  }
}
</script>
