<template>
  <b-container class="import-account-statement-v2 import-tink-wizard bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row no-gutters>
        <b-col>
          <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <h1 class="text-left mb-0">{{ $t(translationPath + 'title') }}</h1>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <div class="step-container-v2">
            <div class="stepper bg-light mx-auto text-regular">
              <form-wizard :title="''" :subtitle="''" :stepSize="'xs'" :color="'#ffffff'"  :errorColor="'#ffffff'" ref="ImportStep" @on-change="onTabChange">
                <tab-content :title="$t(translationPath + 'step_labels.step1')" :before-change="() => validateStep(1)">
                  <import-tink-wizard-step01
                    ref="ImportStep1"
                    @import-selected="mergePartialModels"
                    @last-transaction-date-received="mergePartialModels"
                    @validate-success="setDisabledStatus(!$event, 0)"
                  ></import-tink-wizard-step01>
                </tab-content>
                <tab-content :title="$t(translationPath + 'step_labels.step2')" :before-change="() => validateStep(2)">
                  <import-tink-wizard-step02
                    ref="ImportStep2"
                    :offsetAccount="finalModel.offset_account"
                    @offset-account-updated="mergePartialModels"
                    @connect-status-change="mergePartialModels"
                    @connection-received="mergePartialModels"
                    @new-refresh-credentials-link="mergePartialModels"
                    @validate-success="setDisabledStatus(!$event, 1)"
                    @need-next-tab="$refs.ImportStep.nextTab()"
                  ></import-tink-wizard-step02>
                </tab-content>
                <tab-content :title="$t(translationPath + 'step_labels.step3')" :before-change="() => validateStep(3)">
                  <import-tink-wizard-step03
                    ref="ImportStep3"
                    :offset-account="finalModel.offset_account"
                    :connection="Object.prototype.hasOwnProperty.call(finalModel, 'connection') ? finalModel.connection : null"
                    :tink-provider="Object.prototype.hasOwnProperty.call(finalModel, 'tinkProvider') ? finalModel.tinkProvider : null"
                    @step-valid="mergePartialModels"
                    @validate-success="setDisabledStatus(!$event, 2)"
                    @connection-modified="updateConnectionsList"
                    @connection-received="mergePartialModels"
                    @offset-account-updated="mergePartialModels"
                  ></import-tink-wizard-step03>
                </tab-content>
                <tab-content :title="$t(translationPath + 'step_labels.step4')" :before-change="() => validateStep(4)">
                  <import-tink-wizard-step04
                    ref="ImportStep4"
                    :offset-account="finalModel.offset_account"
                    :tink-provider="Object.prototype.hasOwnProperty.call(finalModel, 'tinkProvider') ? finalModel.tinkProvider : null"
                    :last-transaction-dates="Object.prototype.hasOwnProperty.call(finalModel, 'lastTransactionDates') ? finalModel.lastTransactionDates : {}"
                    :tink-account="Object.prototype.hasOwnProperty.call(finalModel, 'tinkAccount') ? finalModel.tinkAccount : ''"
                    :connection-refreshed-at="Object.prototype.hasOwnProperty.call(finalModel, 'connection') ? finalModel.connection.credentials_refreshed_at : ''"
                    :connection="Object.prototype.hasOwnProperty.call(finalModel, 'connection') ? finalModel.connection : null"
                    @step-valid="mergePartialModels"
                    @transactions-retrieved="mergePartialModels"
                    @import-created="mergePartialModels"
                    @validate-success="setDisabledStatus(!$event, 3)"
                  ></import-tink-wizard-step04>
                </tab-content>
                <tab-content :title="$t(translationPath + 'step_labels.step5')" :before-change="() => validateStep(5)">
                  <import-account-statement-wizard-step03
                    ref="ImportStep5"
                    :finalModel="finalModel"
                    :rawTabShown="isDevelopmentMode"
                    @validate-success="mergePartialModels"
                    @update-transactions="mergePartialModels"
                    @delete-transactions="mergePartialModels"
                    @add-transaction="mergePartialModels"
                  />
                </tab-content>
                <tab-content :title="$t(translationPath + 'step_labels.step6')">
                  <import-account-statement-wizard-step04
                    ref="ImportStep6"
                    :finalModel="finalModel"
                    @validate-success="mergePartialModels"
                    @delete-transactions="mergePartialModels"
                    @disable-save="setDisabledStatus($event, 5)"
                  />
                </tab-content>
                <template slot="footer" slot-scope="props">
                  <div :class="{ hasSpace: props.activeTabIndex != 0 }">
                    <div class="wizard-footer-left">
                      <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle">
                        {{ $t('common.previous') }}
                      </wizard-button>
                    </div>
                    <div class="line" v-if="props.activeTabIndex != 0"></div>
                    <div class="wizard-footer-right">
                      <wizard-button tabindex="6" v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle" :disabled="nextDisabled[props.activeTabIndex]">
                        {{ $t('common.next') }}
                      </wizard-button>
                      <wizard-button v-else @click.native="onComplete" class="wizard-footer-right finish-button" tabindex="5" :style="props.fillButtonStyle" :disabled="nextDisabled[props.activeTabIndex]">{{$t('common.finalize_the_import')}}</wizard-button>
                    </div>
                  </div>
                </template>
              </form-wizard>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <success-modal
      :title="$t(translationPath+'success_modal_title')"
      :message="$t(translationPath+'success_modal_message')"
      :button="$t(translationPath+'success_modal_button_text', {account_name: this.finalModel.offset_account.title})"
      :redirect="'/reports/performance/accounts/' + (finalModel.offset_account ? finalModel.offset_account.id : '') + '/view'"
      :orLinkText="$t('transactions.import.import_wizard_v2.success_modal.create_button_secondary_text')"
      :orLink="'/redirect?to=importTink'"
      @hide="onHideSuccessModal"
    ></success-modal>
  </b-container>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ImportTinkWizardStep01 from './ImportTinkWizardStep01.vue'
import ImportTinkWizardStep02 from './ImportTinkWizardStep02.vue'
import ImportTinkWizardStep03 from './ImportTinkWizardStep03.vue'
import ImportTinkWizardStep04 from './ImportTinkWizardStep04.vue'
import ImportAccountStatementWizardStep03 from '../import-account-statement/ImportAccountStatementWizardStep03'
import ImportAccountStatementWizardStep04 from '../import-account-statement/ImportAccountStatementWizardStep04'
import SuccessModal from '@/components/modals/SuccessModal'

export default {
  name: 'ImportTink',
  components: {
    FormWizard,
    TabContent,
    WizardButton,
    ImportTinkWizardStep01,
    ImportTinkWizardStep02,
    ImportTinkWizardStep03,
    ImportTinkWizardStep04,
    ImportAccountStatementWizardStep03,
    ImportAccountStatementWizardStep04,
    SuccessModal
  },
  data () {
    return {
      translationPath: 'transactions.import.import_tink.',
      nextDisabled: {
        0: true,
        1: true,
        2: true,
        3: true,
        4: false,
        5: false
      },
      finalModel: {
        isConnected: false,
        offset_account: { title: '' },
        tinkAccount: null,
        startDate: null,
        endDate: null,
        transactions: []
      },
      isDevelopmentMode: Boolean(process.env.NODE_ENV === 'development')
    }
  },
  computed: {
    breadcrumbItems () {
      return [
        { text: this.$t('common.header.header_menu.transactions.transactions'), to: '/transactions/import/all' },
        { text: '', to: '', active: true }
      ]
    }
  },
  methods: {
    async validateStep (step) {
      const reference = this.$refs['ImportStep' + step]
      const isValid = await reference.validate()
      return isValid
    },
    mergePartialModels (model) {
      this.finalModel = Object.assign({}, this.finalModel, model)
    },
    setDisabledStatus (value, stepIndex) {
      this.nextDisabled[stepIndex] = value
    },
    updateConnectionsList () {
      if (this.$refs.ImportStep2) {
        this.$refs.ImportStep2.loadConnections()
      }
    },
    async onComplete () {
      const validateTransaction = await this.validateStep('6')
      if (validateTransaction) {
        this.$bvModal.show('successModal')
      }
    },
    onHideSuccessModal () {
      this.$router.push('/redirect?to=importTink')
    },
    onTabChange (oldIndex, newIndex) {
      if (newIndex === 2 && oldIndex === 1 && this.$refs.ImportStep3) {
        this.$refs.ImportStep3.loadTinkAccounts()
      } else if (newIndex === 4 && oldIndex === 3 && this.$refs.ImportStep5) {
        this.$refs.ImportStep5.reset()
      }

      if (this.$refs.ImportStep2) {
        this.$refs.ImportStep2.$removeEvents()
      }
      if (newIndex === 1 && this.$refs.ImportStep2) {
        this.$refs.ImportStep2.$listenEvents()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/settings.scss';
@import '@/assets/scss/form-wizard-2.scss';
@import '@/assets/scss/import-tink-wizard.scss';
</style>
