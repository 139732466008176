<template>
  <div class="tink-import-wizard-step-4-latest-imports">
    <h2>{{ $t(translationPath + 'heading_imports', { accountName: account ? account.title : '' }) }}</h2>
    <loader v-if="!isLoaded" />
    <b-table v-if="isLoaded"
      :items="imports"
      :fields="fieldValues"
    >
      <template v-slot:cell(updated_at)="row">
        <span class="text-nowrap">{{ row.value.slice(0, 10) }}</span>
      </template>
      <template v-slot:cell(title)="row">
        <b-link class="import-link" :to="`/transactions/view?import_id=${row.item.id}`">{{ row.value }}</b-link>
      </template>
    </b-table>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader'

export default {
  name: 'ImportTinkWizardStep04LatestImports',
  components: { Loader },
  props: {
    account: {
      type: Object
    },
    imports: {
      type: Array
    },
    isLoaded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      translationPath: 'transactions.import.import_tink.step3.'
    }
  },
  computed: {
    fieldValues () {
      return [
        { key: 'updated_at', label: this.$t('common.date'), sortable: false },
        { key: 'title', label: this.$t('common.title'), sortable: false }
      ]
    }
  }
}
</script>
