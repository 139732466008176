<template>
  <div class="tink-import-wizard-step-4-latest-transactions">
    <p>{{ $t(translationPath + 'heading_transactions', { accountName: account ? account.title : '' }) }}</p>
    <loader v-if="!isLoaded" />
    <b-table v-if="isLoaded"
      :items="transactions"
      :fields="fieldValues"
    >
      <template v-slot:cell(amount)="row">
        <span>{{ formatAmount(row.value) }}</span>
      </template>

      <template v-slot:cell(title)="row">
        <b-link class="transaction-link" :to="'/transactions/view/transaction/' + row.item.id">{{ row.value }}</b-link>
      </template>
    </b-table>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import axios from 'axios'
import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'

export default {
  name: 'ImportTinkWizardStep04LatestTransactions',
  components: { Loader },
  filters: { formatAmount },
  props: {
    account: {
      type: Object
    }
  },
  data () {
    return {
      transactions: [],
      isLoaded: false,
      translationPath: 'transactions.import.import_tink.step3.'
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    fieldValues () {
      return [
        { key: 'date', label: this.$t('common.date'), sortable: false },
        { key: 'title', label: this.$t('common.title'), sortable: false },
        { key: 'amount', label: this.$t('common.amount'), sortable: false }
      ]
    }
  },
  methods: {
    loadTransactions () {
      if (!this.account) {
        return false
      }

      this.isLoaded = false
      this.imports = []
      axios.get(`${process.env.VUE_APP_ROOT_API}/transactions?account_id=${this.account.id}&limit=10&sort[0][key]=date&sort[0][direction]=desc&sort[1][key]=id&sort[1][direction]=desc`)
        .then(response => {
          this.transactions = response.data.data
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.isLoaded = true
        })
    },
    formatAmount (value) {
      if (!this.currentCOA.locale) {
        return value
      }
      return formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, false)
    }
  },
  watch: {
    account: {
      deep: true,
      handler () {
        this.loadTransactions()
      }
    }
  }
}
</script>
